@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.search_content {
	position: relative;
	display: flex;
	gap: 30px;
	.sidebar {
		min-height: 100vh;
		background-color: getColor(white);
		width: 300px;
		min-width: 300px;
		border: 1px solid color(grey300);
		.sidebarHeader {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid color(grey300);
			margin-bottom: 20px;
			font-size: 14px;
			font-weight: 600;
		}
	}

	.searchProductsBlock {
		width: 75%;
		flex: 1;

		.categoryProductsHeader {
		}

		.productList {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 20px;
			padding: 20px 0;
		}
	}
}

.alltest {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
	.all {
		padding: 10px 20px;
		background-color: #e7f8f3;
		border: 1px solid color(primary);
		border-radius: 5px;
		color: color(primary);
		font-weight: 500;
		cursor: pointer;
		&:hover {
			background-color: color(primary);
			color: color(white);
		}
	}
	.lab {
		padding: 10px 20px;
		background-color: white;
		border: 1px solid color(grey300);
		border-radius: 5px;
		color: color(primary);
		font-weight: 500;
		cursor: pointer;
		&:hover {
			background-color: color(primary);
			color: color(white);
		}
	}

	.labTest_all {
		padding: 10px 20px;
		background-color: white;
		border: 1px solid color(grey300);
		border-radius: 5px;
		color: color(primary);
		font-weight: 500;
		cursor: pointer;
		&:hover {
			background-color: color(primary);
			color: color(white);
		}
	}

	.labTest_lab {
		padding: 10px 20px;
		background-color: #e7f8f3;
		border: 1px solid color(primary);
		border-radius: 5px;
		color: color(primary);
		font-weight: 500;
		cursor: pointer;
		&:hover {
			background-color: color(primary);
			color: color(white);
		}
	}
}
