// AddEdit Profile Bio

.editProfile {
	width: 100%;
	min-width: 440px;
	padding: 20px;

	display: flex;
	flex-direction: column;
	gap: 16px;
}
.btn_action {
	width: 100%;
	border-top: 1px solid var(--Greyscale-300, #eeeff2);
	border-radius: 0px 0px 10px 10px;
	background: var(--ltWhite);
	padding: 20px;
	position: sticky;
	bottom: 0;
}
