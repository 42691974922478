.labtestDetailsDesc {
	background: #fff;
	border-top: 1px solid #eeeff2;
}
.labtestDetailsDesc_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px;
	border-bottom: 1px solid #eeeff2;
}

.icon_wrap {
	width: 100%;
	display: flex;
	// align-items: center;
	// flex-wrap: wrap;
	gap: 10px;
}

.icon_wrap > p {
	text-wrap: nowrap;
}

.chip_container {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	white-space: normal;
}

.icon_wrap_icon {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	// width: 38px;
	// height: 38px;
	// background: var(--ltGreyscale100);
	// border-radius: 50%;
}
.icon_wrap_icon span {
	color: var(--ltGreyscale500);
}

.labtestDetailsDesc_pckg {
	padding: 15px 20px;
	border-bottom: 1px solid #eeeff2;
	display: flex;
	flex-direction: column;
}
.labtestDetailsDesc_desc {
	padding: 15px 20px;
	/* border-bottom: 1px solid #eeeff2; */
}
.labtestDetailsDesc_desc > p {
	line-height: 160%; /* 22.4px */
	margin-top: 10px;
	margin-left: 35px;
}
.labtestDetailsDesc_pckg_list {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.showmore_btn {
	cursor: pointer;
	color: var(--ltPrimary);
}
.labtestDetailsDesc_desc .showmore_btn {
	margin-left: 35px;
	margin-top: 10px;
}

.labtestDetailsDesc_pckg_list_test_requirements {
	color: var(--greyscale-700, #323b49);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 10px;
	margin-left: 20px;
}

@media (min-width: 768px) and (max-width: 1320px) {
	.fasting_required {
		padding-left: 30px;
	}
}
