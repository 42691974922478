.labTestOrders_tabs {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
}

.spinner_wrap {
	padding-top: 50px;
	padding-bottom: 50px;
}
