.singleExperience {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 8px 18px;

  border-radius: 8px;
  border: 1px solid var(--Greyscale-300, #eeeff2);
  background: var(--Others-Base, #fff);

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
  }
}