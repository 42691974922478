.list {
	list-style: none;
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
	}
	.category_list_container {
		&:last-child {
			border-bottom: none;
		}
	}
}
.category_list_container {
	padding-bottom: 10px;
	border-bottom: 1px solid var(--Greyscale-300, #eeeff2);
}
.category_list {
	padding-left: 10px;
	overflow-y: scroll;
	.listItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0px 0px 10px;
	}
}
.category_list_lg {
	height: 180px;
}
.category_list_md {
	height: 150px;
}
.category_list_sm {
	height: 120px;
}

/* Price Range */

.range {
	width: 100%;
	padding-top: 20px;
	padding-left: 10px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 5px;
	.divider {
		margin: 0 5px;
		color: var(--ltGryscale700);
		font-size: 14px;
		font-weight: 500;
	}
	.button {
		background-color: var(--ltPrimary);
		color: white;
		border: none;
		border-radius: 6px;
		padding: 10px 15px;
		font-size: 14px;
		cursor: pointer;
		outline: none;
		transition: 0.3s;
	}
	.applyButton {
		background-color: #ffffff;
		border: none;
		height: 44px;
		width: 44px;
		min-width: 44px;
		min-height: 44px;
		border-radius: 50%;
		padding: 10px;
		font-size: 14px;
		cursor: pointer;
		outline: none;
		transition: 0.3s;
		display: flex;
		align-items: center;
		border: 1px solid var(--ltGreyscale300);
		&:hover {
			background-color: #e6e6e6;
		}
	}
}
.floatingLabelInput {
	position: relative;
	display: flex;
	align-items: center;
	.currencySymbol {
		position: absolute;
		left: 10px;
		font-size: 14px;
	}
	label {
		position: absolute;
		left: 22px;
		top: 24%;
		transition:
			top 0.3s,
			font-size 0.3s;
		font-size: 14px;
	}
	.input {
		width: 100%;
		padding: 8px 10px 8px 20px;
		font-size: 14px;
		border-radius: 6px;
		border: 1px solid #e0e0e0;
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&::placeholder {
			font-size: 14px;
		}
	}
	.focused {
		top: -20px;
		font-size: 12px;
	}
}
