.paymentDetailsCard {
	position: relative;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 10px;
	background: white;
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
	overflow-y: scroll;

	&_header {
		padding: 20px;
		border-bottom: 1px solid var(--ltGreyscale300);
	}
}



