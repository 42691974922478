.addEditExperience {
	width: 100%;
	min-width: 520px;

	.currentWorkingPeriod {
		background: var(--ltGreyscale200);
	}

	.designationPopover,
	.departmentPopover {
		max-height: 180px !important;
	}
}
.btn_action {
	width: 100%;
	border-top: 1px solid var(--Greyscale-300, #eeeff2);
	border-radius: 0px 0px 10px 10px;
	background: var(--ltWhite);
	padding: 20px;
	position: sticky;
	bottom: 0;
}
