/* Main Container */
/* .filtered_by_wrap {
  margin: -10px 0px 20px 0px;
} */
/* Left Side */
/* .filterPrice_body, */
/* Right Side */
/* .testCard_container:hover{
  background: #000;
} */
/* .testCard_container {
	padding: 20px 0;
} */
/* .testCard_container:not(:last-child) {
	border-bottom: 1px solid var(--ltGreyscale300);
} */
/* .testCard_container:first-child {
	padding-top: 0;
} */
/* .testCard_container:last-child {
	padding-bottom: 0;
} */
/* Media Queries */
.test_results_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.labTest_results_container {
	margin-top: 30px;
}
.labtest_container {
	display: flex;
	align-items: flex-start;
	gap: 30px;
	padding-bottom: 30px;
	position: relative;
}
.lg_filtered_by_wrap {
	display: flex;
	align-items: center;
	gap: 10px;
}
.sm_filtered_by_wrap {
	display: flex;
	align-items: center;
	gap: 10px;
}
.filtered_by_body {
	display: flex;
	align-items: center;
	gap: 10px;
}
.filterSection_wrap {
	width: 25%;
	border-radius: 10px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
}
.popularPackages_body {
	padding: 15px 25px;
}
.categories_body {
	padding-bottom: 10px;
}
.labtest {
	width: 75%;
}
.labtest_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.testList {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.testCard_container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.spinner_wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
@media (min-width: 768px) {
	.filterSection_wrap {
		width: 35%;
	}
	.labtest {
		width: 65%;
	}
}
@media (min-width: 1200px) {
	.filterSection_wrap {
		width: 27%;
	}
	.labtest {
		width: 73%;
	}
}
@media (min-width: 1400px) {
	.filterSection_wrap {
		width: 27%;
	}
	.labtest {
		width: 73%;
	}
}
@media (min-width: 1600px) {
	.filterSection_wrap {
		width: 24%;
	}
	.labtest {
		width: 76%;
	}
}
@media (max-width: 1440px) {
	.lg_filtered_by_wrap {
		display: none;
	}
}
@media (min-width: 1441px) {
	.sm_filtered_by_wrap {
		display: none;
	}
}
