.drRatingView {
	border-radius: 6px;
	border: 1px solid var(--ltSecondary);
	background: var(--ltSecondary100);

	padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
