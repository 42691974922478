.specialist {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
	padding: 16px 20px;

	&_item {
		display: flex;
		align-items: center;
		padding: 8px 18px;

		border-radius: 4px;
		background: var(--ltGreyscale200);
	}
}
