.paymentMethodCard {
	border-radius: 12px;
	border: 1px solid var(--ltGreyscale300);
	background: var(--ltWhite);

	.paymentMethod {
		display: flex;
		justify-content: space-between;
    flex-direction: column;
		gap: 16px;
		padding: 16px 20px;
	}
}
