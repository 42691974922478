.consultPatient_info_card {
	border: 1px solid var(--ltGreyscale300);
	border-radius: 8px;
	background: var(--ltWhite);

	&_header {
		padding: 14px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_body {
		display: flex;
		gap: 20px;
		padding: 0px 20px;

		// align-items: center;

		.image {
			width: 120px;
			min-width: 120px;
			height: 120px;
			border-radius: 10px;
			border: 1px solid var(--ltGreyscale300);

      position: relative;
			margin: 14px 0px;

      & > span{
				position: absolute;
				left: 0;
				bottom: 10px;
			}
		}

		.details {
      width: 100%;
			display: flex;
			justify-content: space-between;
			gap: 20px;

			&_personal {
				display: flex;
				flex-direction: column;
        justify-content: center;
				gap: 9px;

				padding: 14px 48px 14px 0px;
			}

			&_payment {
				display: flex;
				flex-direction: column;
        align-items: flex-end;
        justify-content: center;
				gap: 9px;

        border-left: 1px solid var(--ltGreyscale300);
				padding: 14px 28px 14px 48px;
			}
		}
	}

  &_footer {
    padding: 14px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
