.singleQualification {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;

	border-radius: 8px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
	background: var(--Others-Base, #fff);

	.footer {
		display: flex;
		justify-content: space-between;

		.image {
			width: 100%;
      height: 100%;
			min-width: 100px;
			max-width: 100px;
			height: 80px;
		}
	}
}
