.editSpecialist {
	width: 100%;
	height: 100%;
	min-width: 520px;
	max-width: 520px;

	padding: 16px 20px;
}

.specialist_list {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.selectedButton {
	border-radius: var(--radius-full, 9999px) !important;
	padding: 8px 14px !important;
}
.notSelectedButton {
	border-radius: var(--radius-full, 9999px) !important;
	border: 1px solid var(--ltGreyscale300) !important;
	background: var(--ltGreyscale100) !important;
	padding: 8px 14px !important;
}

.btn_action {
	width: 100%;
	border-top: 1px solid var(--ltGreyscale300);
	background: var(--ltWhite);
	border-radius: 0px 0px 10px 10px;
	padding: 20px;
	position: sticky;
	bottom: 0;
}
