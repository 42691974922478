.container {
	width: 100%;
	min-width: 380px;
	max-width: 400px;
	max-height: 500px;
	// overflow-y: scroll;
	position: relative;

	.icon_container_wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		padding: 24px 32px;

		.icon_container {
			padding: 13px;
			background: #fd6a6a1a;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			&.icon_container_bg {
				background-color: #fefae9;
			}
		}

		.content_container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			& p:first-child {
				color: var(--ltGreyscale900);
				font-size: 18px;
				font-weight: 600;
				line-height: 150%; /* 27px */
				color: #1f2937;
				text-align: center;
			}

			& p:last-child {
				color: var(--ltGreyscale600);
				font-size: 14px;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				text-align: center;
			}
		}
	}

	.button_container {
		width: 100%;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		border-top: 1px solid var(--Greyscale-300, #eeeff2);
		border-radius: 0px 0px 10px 10px;
		padding: 24px;
		position: sticky;
		bottom: 0;
	}
}
