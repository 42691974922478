.reports_tabs {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
}
.reports__cards {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: 20px;
	background: #fff;
	padding: 20px;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 10px;
}
.report_header_top {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 20px;
	cursor: pointer;
}
.not_found {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	font-size: 20px;
	font-weight: 500;
	color: var(--ltGreyscale500);
}
