.radio_button {
	cursor: pointer;
	display: flex;
	gap: 10px;

	& > div {
		& > input {
			visibility: hidden;
		}
		& > input[type='radio'] {
			&:checked {
				+ {
					.fake_radiobutton {
						&:after {
							display: block;
						}
					}
				}
			}
		}
	}
}

.fake_radiobutton {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	border: 2px solid var(--ltPrimary);
	background-color: white;

	&:after {
		content: '';
		display: none;
		position: absolute;
		top: 50%;
		bottom: 50%;
		left: 50%;
		right: 50%;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: var(--ltPrimary);
		transform: translateX(-50%) translateY(-50%);
	}
}

.label {
	color: var(--ltGreyscale900);
}
