.chip {
	padding: 4px 14px;
	border-radius: 8px;
	background: #ebf8fe;
	color: var(--ltPrimary);
	width: fit-content;
	text-transform: capitalize;

	// Sizes
	&.sm {
		font-size: 10px;
		font-weight: 400;
	}

	&.lg {
		font-size: 16px;
		font-weight: 400;
	}

	// Variants
	&.bgSuccessDark {
		background: var(--ltSuccessBase);
	}

	&.bgSeashell {
		background: #fff4ed;
	}
	&.bgPrimary {
		background: var(--ltPrimary);
	}

	&.bgCosmicLatte {
		background: #fff9eb;
	}

	&.bgAliceBlue {
		background: #ebf8fe;
	}

	&.bgLavenderBlush {
		background: #fef0f0;
	}

	&.bgBrightGray {
		background: #e7f7ef;
	}

	&.bgWhiteGreyscale300 {
		background: var(--ltWhite);
		border: 1px solid var(--ltGreyscale300);
	}

	&.bgPlaceboBlue {
		background: #ebf2ff;
	}

	/* Colors */

	&.clPrimary {
		color: var(--ltPrimary);
	}

	&.clOrange {
		color: var(--ltOrange);
	}

	&.clAntiFlashWhite {
		color: #e8f5f5;
	}

	&.clWhite {
		color: var(--ltWhite);
	}

	&.clSunglow {
		color: #ffc837;
	}

	&.clPictonBlue {
		color: #38bdf8;
	}

	&.clErrorBase {
		color: var(--ltErrorBase);
	}

	&.clSuccessDark {
		color: var(--ltSuccessBase);
	}

	&.clGreyscale700 {
		color: var(--ltGreyscale700);
	}

	&.clErrorBase {
		color: var(--ltErrorBase);
	}

	&.clBlueRibbon {
		color: #0062ff;
	}
}
