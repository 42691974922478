.addEditExperience {
	width: 100%;
	min-width: 520px;

	.currentWorkingPeriod {
		background: var(--ltGreyscale200);
	}

	.specialityPopover {
		max-height: 180px !important;
	}
}

.certificate_upload {
	background: var(--ltWhite);
  display: flex;
  flex-direction: column;
  gap: 10px;
	.certificate_input_wrapper {
		width: 120px;
		height: 88px;
		border-radius: 6px;
		border: 1px dashed var(--ltGreyscale400);
		position: relative;
	}
}

.btn_action {
	width: 100%;
	border-top: 1px solid var(--Greyscale-300, #eeeff2);
	border-radius: 0px 0px 10px 10px;
	background: var(--ltWhite);
	padding: 20px;
	position: sticky;
	bottom: 0;
}
