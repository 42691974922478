.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 48px;
	height: 48px;
	background: #e8f5f5;
	color: var(--ltPrimary);
	overflow: hidden;
	border-radius: 30px;

	// Sizes
	&.sm_32 {
		width: 32px;
		height: 32px;
	}

	&.md_48 {
		width: 48px;
		height: 48px;
	}

	&.lg_64 {
		width: 64px;
		height: 64px;
	}

	&.xl_84 {
		width: 84px;
		height: 84px;
	}

	// variants
	.bgCultured {
		background: #f3f9f8;
	}
}
