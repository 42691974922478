.reportCard {
	border-radius: 10px;
	width: 100%;
	max-width: 230px;
	overflow: hidden;
}
.reportCard_top {
	position: relative;
	background: #d9d9d9;
	padding: 17px 23px;
	height: 120px;
	background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png');
	background-repeat: no-repeat;
	background-blend-mode: hard-light;
	background-size: 100%;
	background-position: top;
}
.reportCard_top_expand {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 24px;
	height: 24px;
	border-radius: 5px;
	background: #c5c7cb;
	color: #fff;
	cursor: pointer;
	transition: transform 0.25s ease;
	display: flex;
	align-items: center;
	justify-content: center;
}
.reportCard_bottom {
	text-align: center;
	background: var(--ltPrimary);
	padding: 17px 23px;
	color: #fff;
	word-wrap: break-word;
}
.reportCard_top_expand:hover {
	transform: scale(1.2);
}

@media (max-width: 1023px) {
	.reportCard {
		max-width: 200px;
	}
}
