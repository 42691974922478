.paitent_num {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid #e8f5f5;
	background: #ffffff;
	padding: 12px 20px;
	overflow: hidden;
	width: 100%;

	&:hover {
		background: rgba(11, 183, 131, 0.05);
		color: var(--ltPrimary);
	}

	&.checked {
		background: rgba(11, 183, 131, 0.05);
		color: var(--ltPrimary);
	}

	&:last-child {
		border-radius: 0px 0px 10px 10px;
	}
}
