.drProfile {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 20px;

	.drProfile_body {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 16px;

		.basic_info {
			width: 100%;
			min-width: 250px;
			max-width: 30%;
			// border-radius: 12px;
			// border: 1px solid var(--ltWhite);
			// background: var(--ltWhite);
		}

		.professional_info {
			width: 100%;
			max-width: 70%;
		}
	}
}
