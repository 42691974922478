.categoryTypePageTemplate {
	margin-top: 30px;
	margin-bottom: 20px;

	&_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;

		.category_items {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			padding: 4px;
			border-radius: 10px;
			border: 1px solid var(--ltGreyscale300);
		}
	}

	.category_list {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 20px;
	}
}

@media screen and (max-width: 1440px) {
	.categoryTypePageTemplate {
		.category_list {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}

@media screen and (max-width: 1320px) {
	.categoryTypePageTemplate {
		.category_list {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

@media screen and (max-width: 1200px) {
	.categoryTypePageTemplate {
		.category_list {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
