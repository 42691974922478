.button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	border: 1px solid var(--ltPrimary);
	background: var(--ltPrimary);
	color: var(--ltWhite);
	padding: 12px 20px;
	border-radius: 6px;
	outline: none;
	border: none;
	cursor: pointer;
	max-height: 44px;

	/* Sizes */
	&.xs {
		padding: 3px 12px;
	}
	&.xs1 {
		padding: 6px 12px;
	}
	&.sm {
		padding: 8px 20px;
	}
	&.md {
		padding: 12px 36px;
	}
	&.lg {
		padding: 12px 56px;
	}
	&.xl {
		padding: 16px 56px;
	}
	&.fullWidth {
		width: 100%;
	}

	/* Variants */

	&.bgPrimary {
		background: var(--ltPrimary);
		border: 1px solid var(--ltPrimary);
	}
	&.bgPrimaryOp_01 {
		border: 1px solid var(--ltPrimary);
		background: #0e76731a;
	}
	&.bgError {
		background: var(--ltErrorLight);
	}
	&.bgErrorBase {
		border: 1px solid var(--ltOrange);
		background: var(--ltErrorBase);
	}
	&.bgErrorDark {
		background: var(--ltErrorDark);
	}
	&.bgOrange {
		border: 1px solid var(--ltOrange);
		background: var(--ltOrange);
	}
	&.bgOrange_01 {
		border: 1px solid var(--Others-Orange, #fe964a);
		background: #fe964a1a;
	}
	&.bgWhiteOrange_01 {
		border: 1px solid var(--Others-Orange, #fe964a);
		background: var(--ltWhite);
	}
	&.bgWhiteGreyscale300 {
		background: var(--ltWhite);
		border: 1px solid var(--ltGreyscale300);
	}
	&.bgWhiteErrorLight {
		background: var(--ltWhite);
		border: 1px solid var(--ltErrorLight);
	}
	&.bgWhiteErrorDark {
		background: var(--ltWhite);
		border: 1px solid var(--ltErrorDark);
	}
	&.bgWhitePrimary {
		background: var(--ltWhite);
		border: 1px solid var(--ltPrimary);
	}
	&.disabled {
		background: var(--ltGreyscale400);
	}
	&:disabled {
		background: var(--ltGreyscale400);
	}
	&.bgGreyscale300 {
		background: var(--ltGreyscale300);
	}
	&.bgWhite {
		background: var(--ltWhite);
	}
	&.bgCultured {
		background: #f1f7f7;
	}

	/* Colors */

	&.clWhite {
		color: var(--ltWhite);
	}
	&.clErrorBase {
		color: var(--ltErrorBase);
	}
	&.clErrorDark {
		color: var(--ltErrorDark);
	}
	&.clPrimary {
		color: var(--ltPrimary);
	}
	&.clGreyscale600 {
		color: var(--ltGreyscale600);
	}
	&.clGreyscale700 {
		color: var(--ltGreyscale700);
	}
	&.clGreyscale900 {
		color: var(--ltGreyscale900);
	}
	&.clOrange {
		color: var(--ltOrange);
	}
	&.clOrange_01 {
		color: var(--Others-Orange, #fe964a);
	}
}

@media (max-width: 1023px) {
	.button {
		padding: 10px 12px;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.button {
		font-size: 12px;
	}
}
